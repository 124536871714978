<template>
	<f-card>
		<v-data-table
			:headers="headers"
			:items="tickets"
			@click:row="view"></v-data-table>
	</f-card>
</template>

<script>
import DateFormater from "../../helpers/date_formater";

export default {
	data: () => ({
		// headers: [
		//     {
		//         text: 'Mês',
		//         align: 'start',
		//         sortable: true,
		//         value: 'date',
		//     },
		//     {
		//         text: 'Total',
		//         value: 'total',
		//         align: 'end'
		//     },
		// ]
	}),
	computed: {
		headers() {
			return [
				{
					text: "ID",
					align: "start",
					sortable: true,
					value: "id",
				},
				{
					text: "Status",
					align: "start",
					sortable: true,
					value: "status",
				},
				{
					text: this.$t("labels.createdAt"),
					value: "created_at",
					align: "end",
				},
			];
		},
		tickets() {
			return this.$store.state.contact.list.map((item) => {
				var status = item.status;
				switch (status) {
					case "waiting_seller":
						item.status = this.$t("pages.contact.status.waitingSeller");
						break;
					case "waiting_admin":
						item.status = this.$t("pages.contact.status.waitingFluke");
						break;
					case "closed":
						item.status = this.$t("pages.contact.status.closed");
						break;
				}
				return item;
			});
		},
	},
	created() {
		this.getData();
	},
	methods: {
		async getData() {
			this.$store.dispatch("contact/list");
		},
		view(data) {
			// console.log( 'view', data )
			this.$router.push({
				name: "contact-view",
				params: {
					id: data.id,
				},
			});
		},
	},
};
</script>
