<template>
	<div>
		<f-default-header
			:title="$t('pages.contact.title')"
			:subtitle="$t('pages.contact.subtitle')" />

		<f-list />

		<f-actions-btn-holder>
			<v-btn color="primary" elevation="0" large @click="create">
				{{ $t("labels.btnNewTicket") }}
			</v-btn>
		</f-actions-btn-holder>
	</div>
</template>

<script>
import FList from "../../components/contact/List.vue";

export default {
	components: {
		FList,
	},
	methods: {
		create() {
			this.$router.push({
				name: "contact-create",
			});
		},
	},
};
</script>
